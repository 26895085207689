import { render, staticRenderFns } from "./PanelSc01Speak.vue?vue&type=template&id=5eae0e99&scoped=true"
import script from "./PanelSc01Speak.vue?vue&type=script&lang=js"
export * from "./PanelSc01Speak.vue?vue&type=script&lang=js"
import style0 from "./PanelSc01Speak.vue?vue&type=style&index=0&id=5eae0e99&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eae0e99",
  null
  
)


    import installComponents from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCollapse, BButton} from 'bootstrap-vue'
    installComponents(component, {BCollapse, BButton})
    


    import installDirectives from "!/home/gitlab-runner/builds/_LQxgyuP/0/skolkovo/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBToggle, VBTooltip} from 'bootstrap-vue'
    installDirectives(component, {'b-toggle': VBToggle, 'b-tooltip': VBTooltip})
    

export default component.exports