<template>
	<div
		:class="'panel programm_schedule ' +
			(isHeaderIndent? ' mt-0 pt-1 ' : '') +
			(isFooterIndent? ' mb-0 pb-1 ' : '') +
			block.appearance"
	>
		<div
			class="container"
			:class="[
				isBrand? 'sk' : '',
			]"
		>
			<div v-if="isHeaderVisible" class="panel_header">
				<h2 class="mb-3">
					{{ block.block_title }}
				</h2>
				<slides-menu-block
					v-if="hasTabs"
					v-model="tab"
					:items="tabs"
					disable-all
				/>
			</div>

			<ul v-if="block.accordion !==null && block.accordion === 1" class="program_accordeon_list">
				<li
					v-for="(item, index) in block.blocks[tab].content"
					:key="index"
					class="row d-flex align-self-center justify-content-start"
				>
					<div class="col-12 col-lg-3">
						<div class="module_data">
							<div v-if="'title' in item" class="module_title">
								{{ item.title }}
							</div>
							<div v-if="item.custom_date !==null" class="description">
								<span>{{ item.custom_date }}</span>
							</div>
							<div v-else-if="item.date_start" class="description">
								<span>{{ item.date_start | moment('DD MMMM YYYY') }}</span> -
								<span>{{ item.date_end | moment('DD MMMM YYYY') }}</span>
							</div>
							<div v-if="item.location !==null" class="description mt-2" v-html="item.location" />
						</div>
					</div>

					<div class="col-12 col-lg-9">
						<div
							v-for="(sub_item, sub_index) in item.content"
							:key="sub_index"
							class="module_item"
						>
							<div class="theme" />
							<div v-if="'name' in sub_item" class="title">
								{{ sub_item.name }}
							</div>
							<peopleLine v-if="sub_item.speakers.length" :items="sub_item.speakers" />
							<div class="description">
								<p v-if="'description' in sub_item">
									{{ sub_item.description }}
								</p>
							</div>
							<client-only>
								<b-collapse :id="'module-collapse-' + index">
									<div v-if="'answer' in sub_item" class="content description" v-html="sub_item.answer" />

									<dynamic-button
										v-if="item.button"
										class="mb-4"
										:button="item.button"
										:block-id="item.id"
										:scenario="item.button.button_scenario"
									/>
								</b-collapse>
							</client-only>
						</div>
					</div>

					<div class="col-12">
						<div class="module_footer">
							<b-button
								v-b-toggle="'module-collapse-' + index"
								v-b-tooltip.hover
								variant="resize"
								class="icon-chevron"
								:title="$t('moreDetails')"
							/>
						</div>
					</div>
				</li>
			</ul>

			<!-- Вариант - не расхлоп -->
			<ul v-else class="program_list">
				<li
					v-for="(item, index) in block.blocks[tab].content"
					:key="index"
					class="row d-flex align-self-center justify-content-start"
				>
					<div class="col-12 col-sm-12 col-lg-12">
						<div class="row schedule_data">
							<div class="col-12 col-md-4">
								<div class="module_data">
									<div v-if="'title' in item" class="module_title">
										{{ item.title }}
									</div>
									<div v-if="item.custom_date !==null" class="description">
										<span>{{ item.custom_date }}</span>
									</div>
									<div v-else-if="item.date_start" class="description">
										<span>{{ item.date_start | moment('DD MMMM YYYY') }}</span> -
										<span>{{ item.date_end | moment('DD MMMM YYYY') }}</span>
									</div>
									<div
										v-if="item.location !==null"
										class="description mt-2"
										v-html="item.location"
									/>
								</div>
							</div>
							<div class="col-12 col-md-8">
								<div v-for="(sub_item, sub_index) in item.content" :key="sub_index" class="module_item">
									<div v-if="'name' in sub_item" class="title">
										{{ sub_item.name }}
									</div>
									<div v-if="'answer' in sub_item" class="content description" v-html="sub_item.answer" />

									<peopleLine v-if="sub_item.speakers.length" :items="sub_item.speakers" />
								</div>
							</div>
							<div class="col-12">
								<dynamic-button
									v-if="item.button"
									:button="item.button"
									:block-id="item.id"
									:scenario="item.button.button_scenario"
								/>
							</div>
						</div>
					</div>
				</li>
			</ul>

			<div v-if="isFooterVisible" class="panel_footer">
				<div class="row">
					<div class="col-12">
						<dynamic-button
							v-if="block.button"
							:button="block.button"
							:block-id="block.id"
							:scenario="block.button.button_scenario"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import '@/utils/moment';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import panel from '~/mixins/panel';
import PeopleLine from '~/components/blocks/people/NewPeopleLine.vue';
import SlidesMenuBlock from '~/components/refactor/SlidesMenuBlock.vue';

export default {
	name: 'PanelSc01speak',
	components: {
		SlidesMenuBlock,
		PeopleLine,
	},
	directives: {
		swiper: directive,
	},
	mixins: [panel],
	data() {
		return {
			tab: '0',
			options: {
				slidesPerView: 'auto',
				spaceBetween: 0,
				cssMode: true,
				loop: false,
			},
		};
	},
	computed: {
		hasTabs() {
			return Array.isArray(this.block.blocks) && this.block.blocks.length > 0;
		},
		tabs() {
			if (!this.hasTabs) return null;

			const t = {};
			// eslint-disable-next-line camelcase
			this.block.blocks.forEach(({ title }, index) => {
				// eslint-disable-next-line camelcase
				t[index] = title;
			});
			return t;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "~/assets/styles/components/home/panel-explist";
@import "~/assets/styles/components/home/panel-schedule";

.programm_schedule {
	// margin-top: 3rem;
	.panel_footer {
		margin-top: 1.5rem;
	}

	.title {
		margin-bottom: 1rem;
	}

	.schedule_list {
		border-bottom: 1px solid $body-color; // margin-bottom: 3rem;
	}

	.schedule_time {
		margin-bottom: 1rem;
		height: auto;
	}
}
</style>
